import { defineStore } from 'pinia'
// import randomstring from 'randomstring'

export const userTokenStore = defineStore('user_token', {
  state: () => ({
    current_token: '',
    nonce: Math.random().toString(36).slice(2),
    state: Math.random().toString(36).slice(2),
  }),
  actions: {
    valid_token() {
      true;
    },
  },
})