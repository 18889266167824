<template>
  <div>
    <h3>Patient View</h3>
  </div>
</template>

<script>
export default {
  setup() {
    return {
      name: 'PatientContent'
    }
  },
}
</script>