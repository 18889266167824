<template>
  <footer class="fixed-bottom d-flex flex-wrap justify-content-center py-2 px-2 border-top bg-white">
    <p class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
      &copy;Bamboo Health 2022 - All Rights Reserved
    </p>
  </footer>
</template>

<script>
export default {
  setup(){
    return {
      name: 'FooterContent'
    }
  }
}
</script>
