<template>
  <div>

  </div>
</template>

<script>
export default {
  setup() {
    return {
      name: 'LoginHandler'
    }
  },
}
</script>