<template>
  <div class="btn athenahealthsignin logo_white" @click="login">
    <div class="ah_login_left_div">
      Log in with
    </div>
    <div class="ah_login_right_div">
      <img class="logo" alt="athenahealth" title="athenahealth" src="https://dy2j7zawvtj7i.cloudfront.net/login-with-athenahealth/latest/img/athenahealth_logo_white.svg" />
    </div>
  </div>
</template>

<script>
import { userTokenStore } from '@/store/user_token'

export default {
  setup(){
    // refactor to application / environment level config.
    const athena_config = {
      client_id: '0oaf1s9zcsRfLZtmi297',
      scopes: 'user/Patient.read user/CarePlan.read user/Provider.read'
    }
    const user_token = userTokenStore()
    return {
      name: 'AthenaLoginWidget',
      login: () => {
        console.log("Initiating login to Athena Health");
        console.log(athena_config);
        console.log('Token => ' + user_token.current_token);
        console.log('nonce => ' + user_token.nonce);
        console.log('state => ' + user_token.state);

        const targetUrl = new URL("https://myidentity.platform.athenahealth.com/oauth2/v1/authorize")
        targetUrl.searchParams.set('response_type', 'code')
        targetUrl.searchParams.set("client_id", athena_config.client_id)
        targetUrl.searchParams.set("redirect_uri", window.location.origin + '/login')
        targetUrl.searchParams.set("scope", athena_config.scopes)
        targetUrl.searchParams.set("state", user_token.state)
        targetUrl.searchParams.set("nonce", user_token.nonce)
        console.log('target => ' + targetUrl.toString());
        window.location.href = targetUrl;
      }
    }
  }
}
</script>