
import { createRouter, createWebHistory } from 'vue-router'
import HomeContent from '../components/HomeContent'
import LoginHandler from '../components/LoginHandler'
import CareGapContent from '../components/CareGapContent'
import PatientContent from '../components/PatientContent'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeContent
  },
  {
    path: '/login',
    name: 'login',
    component: LoginHandler
  },
  {
    path: '/care_gaps/:care_gap',
    name: 'care_gap_detail',
    component: CareGapContent,
    props: true
  },
  {
    path: '/patients/:patient_id',
    name: 'patient_detail',
    component: PatientContent,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
