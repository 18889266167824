import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

// Bootstrap CSS
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

// Pinia Store
const pinia = createPinia()

const surfacing_app = createApp(App)

surfacing_app
  .use(BootstrapVue3)
  .use(router)
  .use(pinia)

surfacing_app.mount('#app')
