<template>
  <div class="home-content">
    <h1>Bamboo Health EHR Integration POC</h1>
  </div>
</template>

<script>

export default {
  name: 'HomeContent'
}
</script>