<template>
  <div class="container">
    <header class="d-flex flex-wrap justify-content-center py-3 mv-4 border-bottom">
      <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
        <img :src="home_logo" />
      </a>
      <b-nav>
        <b-nav-item v-if="is_authenticated === false">
          <AthenaLoginWidget />
        </b-nav-item>
      </b-nav>
    </header>
  </div>
</template>

<script>
import home_logo from "../assets/img/desktop-logo.png"
import AthenaLoginWidget from './AthenaLoginWidget'

export default {
  setup(){
    const is_authenticated = false;
    return {
      name: 'HeaderContent',
      home_logo,
      is_authenticated,
    }
  },
  components: { AthenaLoginWidget }
}
</script>
